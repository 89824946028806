import { Outlet, NavLink } from "react-router-dom";
import "../css/Home.css";

function Root() {
	return (
		<div className="App max-w m-auto">
		<header>
			<div>
				<span className="title">
					<span className="weburl tleft">
						<span className="grey">://</span>
						<a className="page-url" href="https://gohar.dev">
							gohar
						</a>
					</span>
				</span>
				
				<nav> 
					<ul>
						<NavLink to={`/`} className="menu-item" activeclassname="active" href="/">Home</NavLink>
						<NavLink to={`projects`} className="menu-item" href="/projects">Projects</NavLink>
						<NavLink to={`blog`} className="menu-item" href="/blog">Blog</NavLink>
					</ul>
				</nav>
			</div>

			<hr/>
		</header>



		<div className="content-container">
			<Outlet />
		</div>

		
		<footer>
		</footer>

		</div>
	)
}

export default Root;