import '../css/Project.css';

function Project() {
	return (
			<div className="project-page">
				<div className="projects-holder">
					<div className="container-style1">
						<h4>
							Zodiac
							<a className="github-image" href="https://github.com/Nukestye/Zodiac" rel="noreferrer" target="_blank">
								<img className="social-button-logo" src={require("../res/github-mark-white.png")} height="12" width="12" alt="white github logo"/>
							</a>
						</h4>
						<p className="github-stars">★ 20</p>
						<p className="text">Languages:</p>
						<span className="tag-holder1">
							<span className="tag-js"></span>
						</span>
					</div>
					<div className="container-style1">
						<h4>
							Emperor
							<a className="github-image" href="https://github.com/UOW-Computing/Emperor" rel="noreferrer" target="_blank">
								<img className="social-button-logo" src={require("../res/github-mark-white.png")} height="12" width="12" alt="white github logo"/>
							</a>
						</h4>
						<p className="github-stars">★ 7</p>
						<p className="text">Languages:</p>
						<span className="tag-holder1">
							<span className="tag-python"></span>
						</span>
					</div>
				</div>
			</div>
	)
}

export default Project;