import '../css/Home.css';

function Home() {
  return (

		<div className="intro-part">
			<h1 className="homepage-title no-mtb">Hello, <br/>I am Gohar.</h1>
			<p className="upcoming-job small-font no-mtb">An upcoming Software Engineer.</p>
			<ul className="social-buttons small-font no-bullet">
				<a className="horizontal li-button" href="https://www.github.com/Nukestye" rel="noreferrer" target="_blank">
					<img className="social-button-logo" src={require("../res/github-mark-white.png")} height="16" width="16" alt="white github logo"/>
					<span className="social-button-text">Github</span>
				</a>
				<a className="horizontal li-button" href="https://www.linkedin.com/in/goharhayat/" rel="noreferrer" target="_blank">
					<img className="social-button-logo" src={require("../res/In-White-14.png")} height="14" width="24" alt="linkedin logo"/>
				</a>
			</ul>
			<br/>
			<p className="short-desc small-font">
				Welcome to my profile!
				<br/><br/>
				This paragraph here would have details about my aspirations and what I currently do. However, at this moment, there is just this piece of paragraph instead
				of the usual Lorem ipsum, which fills this empty space with bunch of text to see whether or not this style is good.
				<br/><br/>
				Join me on this exciting journey of programming and shared interests!
			</p>



			<div className="blog-part">
				<h3>Blog</h3>
				<div className="latest-blog-list">
					<p style={{display: "table-cell", verticalAlign: "middle", color: "grey"}}>Nothing to see here!</p>
				</div>
			</div>
    	</div>
  );
}

export default Home;
