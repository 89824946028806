import { useRouteError } from "react-router-dom";

function NotFound() {
	const error = useRouteError();
	console.log(error);

	return (
		<div>
			Not Found 404
		</div>
	)
}

export default NotFound;