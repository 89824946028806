import React  from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import Home from './Components/Home';
import Blog from './Components/Blog';
import Project from './Components/Projects'
import Root from './Components/root'
import NotFound from './Components/not-found'


const router = createBrowserRouter([
	{
		path: "/",
		element: <Root />,
		errorElement: <NotFound />,
		children: [
			{
				index: true,
				element: <Home />
			},
			{
				path: "projects",
				element: <Project />
			},
			{
				path: "blog",
				element: <Blog />
			}
		]
	},
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router}/>
);